<template>
  <Main>
    <div v-if="accountData?.attributes" class="trading-account">
      <sdPageHeader class="trading__header">
        <template v-slot:title>
          <div class="account__wrapper">
            <p class="account__title">
              {{
                i18n.t(
                  `accounts.trading.${accountData.attributes.mt5_account_data.kind}`,
                  {
                    account: ': ' + accountData.attributes.mt5_account_data.name,
                  },
                  3,
                )
              }}
            </p>
            <Status
              :status="accountData.attributes.contest?.status ?? accountData.attributes.mt5_account_data.status"
              v-if="!isPc"
            />
            <TradingContestTag :contest="accountData.attributes.contest" />
          </div>
        </template>
        <template v-slot:buttons>
          <div class="account__wrapper">
            <p class="account__subtitle" v-if="isPc">
              <Status
                :status="
                  accountData.attributes.mt5_account_data.status != 'pending'
                    ? accountData.attributes.contest?.status ?? accountData.attributes.mt5_account_data.status
                    : 'pending'
                "
              />
            </p>
            <p class="account__subtitle">
              <span>
                {{ `${i18n.t('accounts.trading.leverage')}` }}
                <br />
              </span>
              1:{{ accountData.attributes.mt5_account_data.leverage }}
            </p>
            <p class="account__subtitle">
              <span>
                {{ `${i18n.t('accounts.amount')}` }}
                <br />
              </span>
              {{ i18n.n(+accountData.attributes.amount, 'decimal') }}
              <span>{{ accountData.attributes.amount_currency }}</span>
            </p>
            <div class="account__buttons page">
              <ActionButtons
                :accountData="accountData"
                v-model:closingModal="closeTradingModal"
                @openModal="openModal"
                @openPublishModal="openPublishModal"
                @openModalCheck="openModalCheck"
              />
            </div>
          </div>
        </template>
      </sdPageHeader>
      <sdCards headless>
        <!--<sdHeading as="h5"> {{ i18n.t('accounts.trading.authorization') }}</sdHeading>-->
        <div class="trading-page__auth">
          <TradingAuth :tradingInfo="accountData.attributes.mt5_account_data" />
        </div>
      </sdCards>
      <GraphComponent />

      <a-tabs @change="changeTab" v-model:activeKey="activeKey" size="large" type="card">
        <a-tab-pane key="1" :tab="i18n.t('accounts.trading.deals')">
          <DealsCrud @reloadTable="onChangeDeals" />
        </a-tab-pane>
        <a-tab-pane key="2" :tab="i18n.t('transactions.title')">
          <TableWrapper class="table-data-view table-responsive" v-if="transactionsData">
            <a-table :dataSource="dataSourceTransactions" :pagination="false" :columns="columnsTransactions">
              <template #operableType="record">
                <p v-if="record.record.comment">{{ record.record.comment }}</p>
                <p v-else>
                  {{ i18n.t('transactions.' + record.record.operable_type) }}
                  {{
                    record.record.operable_type === 'Transfer'
                      ? i18n.t('transactions.from') +
                        i18n.t('transactions.' + record.record.operation_info.account_from.kind + 'Name', 1) +
                        i18n.t('transactions.to') +
                        i18n.t('transactions.' + record.record.operation_info.account_to.kind + 'Name', 2)
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'Deposit'
                      ? i18n.t(
                          'transactions.' +
                            (record.record.operation_info ? record.record.operation_info.kind : 'cancelWithdrawal'),
                          {
                            tokens: record.record.operation_info.amount,
                            name: record.record.user?.full_name,
                            ref: record.record.user?.user_referal,
                          },
                        )
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'Withdrawal' && record.record.operation_info
                      ? i18n.t('transactions.onWallet') + '"' + record.record.operation_info.name + ''
                      : record.record.operable_type === 'Withdrawal'
                      ? i18n.t('transactions.fromAdmin')
                      : ''
                  }}
                  {{
                    record.record.operable_type === 'Exchange'
                      ? i18n.t('transactions.ExchangeTokens', {
                          usd: Number(record.record.operation_info.amount_from),
                          mth: record.record.operation_info.amount_to,
                        })
                      : ''
                  }}
                </p>
              </template>
            </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="transactionsMeta.current"
                :total="transactionsMeta.count"
                @change="onChangeTransactions"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
          <sdCards headless v-else>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane
          key="3"
          :tab="i18n.t('transactions.Deposit', 2)"
          @change="onChangePayments"
          v-if="accountData.attributes.mt5_account_data.kind === 'demo'"
        >
          <TableWrapper class="table-data-view table-responsive" v-if="coinPaymentsData">
            <a-table :dataSource="dataSourcePayments" :pagination="false" :columns="columnsPayments">
              <template #buildOptionText="props">
                <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
              </template>
              <template #customTitleStatus>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('operation_status_text')">
                  <span>
                    {{ i18n.t('start.operation_status_text') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'operation_status_text'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleDate>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('created_at')">
                  <span>
                    {{ i18n.t('start.created_at') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'created_at'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #operation_id="record">
                <!--<p
                  @click="openModalDeposit(record.record.id)"
                  style="color:#1890ff;cursor:pointer;"
                  v-if="
                    record.record.operation_status_text == 'waiting' ||
                      record.record.operation_status_text == 'Waiting for confirms...' ||
                      record.record.operation_status_text == 'Waiting for confirms' ||
                      record.record.operation_status_text == 'Waiting for buyer funds' ||
                      record.record.operation_status_text == 'Waiting for buyer funds...'
                  "
                >
                  {{ record.record.operation_id }}
                </p>
                <p v-else>
                  {{ record.record.operation_id }}
                </p>-->
                <p>
                  {{ record.record.operation_id }}
                </p>
              </template>
              <template #exchange_amount="record">
                {{ record.record.exchange_amount }} {{ record.record.exchange_currency }}
              </template>
              <template #exchange_rate="record">
                {{ record.record.exchange_rate }}
              </template>
              <template #request_amount="record">
                {{ record.record.request_amount }} {{ record.record.exchange_currency }}
              </template>
              <template #operation_status_text="record">
                {{ i18n.t('statuses.trading_account.' + record.record.operation_status_text) }}
              </template>
              <template #created_at="record">
                {{ i18n.d(record.record.created_at, 'long') }}
              </template>
            </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="paymentsMeta.current"
                :total="paymentsMeta.count"
                @change="onChangePayments"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
          <sdCards headless v-else>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane
          key="4"
          :tab="i18n.t('accounts.transfers')"
          v-if="accountData.attributes.mt5_account_data.kind === 'real'"
        >
          <TableWrapper class="table-data-view table-responsive" v-if="transfersData">
            <a-table :dataSource="dataSourceTransfers" :columns="columnsTransfers" :pagination="false">
              <template #customTitleAmount>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('amount')">
                  <span>
                    {{ i18n.t('transactions.amount') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'amount'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleCreated>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('created_at')">
                  <span>
                    {{ i18n.t('transactions.created_at') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'created_at'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleStatus>
                <div style="display: flex; cursor: pointer" @click="sortTablePayments('status')">
                  <span>
                    {{ i18n.t('transactions.status') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'status'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
            </a-table>
            <div style="text-align: right; margin-bottom: 20px">
              <a-pagination
                :default-page-size="pageSize"
                v-model:pageSize="pageSize"
                :show-size-changer="true"
                v-model:current="transfersMeta.current"
                :total="transfersMeta.count"
                @change="onChangeTransfer"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
          <sdCards headless v-else>
            <a-skeleton active :paragraph="{ rows: 10 }" />
          </sdCards>
        </a-tab-pane>
      </a-tabs>
    </div>

    <a-spin v-else class="loading" size="large" />
    <ModalCash :visibleModal="visibleModalAction" @closeModal="closeModal" />
    <sdModal
      centered
      type="primary"
      :title="i18n.t('start.coinPaymentsDepositCheck')"
      :visible="visibleModalDeposit"
      :onCancel="closeModal"
    >
      <DepositCoinpayments :depositId="depositId" />
    </sdModal>
    <PublishModal v-model:data="publishModal" />
    <CloseTradingModal v-model:data="closeTradingModal" />
    <CheckModal v-model:data="checkModal" />
  </Main>
</template>

<script>
import { Main, TableWrapper } from '../styled';
import { useI18n } from 'vue-i18n';
import { onMounted, computed, defineAsyncComponent, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const DealsCrud = defineAsyncComponent(() => import('./overview/DealsCrud.vue'));
const TradingAuth = defineAsyncComponent(() => import('@/components/elements/TradingAuth.vue'));
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const DepositCoinpayments = defineAsyncComponent(() => import('@/components/modals/overview/DepositCoinpayments'));
const GraphComponent = defineAsyncComponent(() => import('@/components/elements/GraphComponent'));
const TradingContestTag = defineAsyncComponent(() => import('@/components/elements/TradingContestTag'));
const PublishModal = defineAsyncComponent(() => import('@/components/modals/PublishModal'));
const CloseTradingModal = defineAsyncComponent(() => import('@/components/modals/CloseTradingModal'));
const CheckModal = defineAsyncComponent(() => import('@/components/modals/CheckModal'));
const ActionButtons = defineAsyncComponent(() => import('./overview/ActionButtons'));

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import Status from '@/components/elements/Status';

const TradingAccountInfoPage = {
  name: 'TradingAccountInfoPage',
  components: {
    Main,
    TableWrapper,
    DealsCrud,
    ModalCash,
    GraphComponent,
    DepositCoinpayments,
    Status,
    TradingAuth,
    TradingContestTag,
    ActionButtons,
    PublishModal,
    CloseTradingModal,
    CheckModal,
  },
  setup() {
    const { state, dispatch } = useStore();
    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();

    const linkValue = ref('id');
    const order = ref('desc');
    const pageSize = ref(10);

    const isPc = ref(window.innerWidth > 500);

    const accountData = computed(() => state.tradingAccounts.accountData);
    const transactionsData = computed(() => state.accounts.transactionsData);
    const transactionsMeta = computed(() => state.accounts.transactionsMeta);
    const coinPaymentsData = computed(() => state.accounts.coinPaymentsData);
    const coinPaymentInfo = computed(() => state.accounts.coinPaymentInfo);
    const transfersData = computed(() => state.accounts.transfersData);
    const transfersMeta = computed(() => state.accounts.transfersMeta);
    const paymentsMeta = computed(() => state.accounts.paymentsMeta);
    const pageNum = computed(() => route.params.pageNum);
    const accountType = computed(() => accountData.value.attributes.mt5_account_data.kind);
    const getableData = computed(() => {
      return {
        accId: route.params.accId,
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        sortableLink: `&sort_by=${linkValue.value}&order=${order.value}`,
      };
    });

    const accId = route.params.accId;
    const visibleModalAction = ref(false);
    const visibleModalDeposit = ref(false);
    const depositId = ref(null);
    const transactionView = ref(true);
    const activeKey = ref('1');
    const currentPage = ref(1);
    const publishModal = ref({ visible: false, data: null });
    const closeTradingModal = ref({ visible: false, data: null });
    const checkModal = ref({ data: null, visible: false });

    const changeTab = (e) => {
      currentPage.value = e;
      if (e == 1) {
        onChangeDeals(1);
      } else if (e == 2) {
        onChangeTransactions(1);
      } else if (e == 3) {
        onChangePayments(1);
      } else if (e == 4) {
        onChangeTransfers(1);
      }
    };

    const closeModal = (closeWithoutPush) => {
      visibleModalAction.value = false;
      visibleModalDeposit.value = false;
      depositId.value = null;
      dispatch('getTradingAccount', { id: accId });
      if (!closeWithoutPush) {
        router.push('/trading-account/' + accId + '/page/' + pageNum.value);
      }
    };

    const openModal = (data) => {
      router.push(`/trading-account/${data.id}/page/${pageNum.value}/trading-${data.type}`);
      visibleModalAction.value = true;
    };

    const openModalDeposit = (data) => {
      visibleModalDeposit.value = true;
      depositId.value = data;
      dispatch('getCoinpaymentsDepositInfo', data);
    };

    const openModalCheck = (data) => {
      checkModal.value = { visible: true, data: data };
    };

    const sortTablePayments = (value) => {
      if (linkValue.value == value) {
        order.value == 'asc' ? (order.value = 'desc') : (order.value = 'asc');
      }
      linkValue.value = value;
      getableData.value.sortableLink = `&sort_by=${value}&order=${order.value}`;
      dispatch('getAllCoinpaymentsDeposits', getableData.value);
    };

    const onChangeTransactions = (page) => {
      getableData.value.pageNum = page;
      dispatch('getTransactions', getableData.value);
    };

    const onChangePayments = (page) => {
      getableData.value.pageNum = page;
      if (accountType == 'real') {
        dispatch('getAllCoinpaymentsDeposits', getableData.value);
      } else {
        dispatch('getDemoDepositsRequests', getableData.value);
      }
    };

    const onChangeTransfers = (page) => {
      getableData.value.pageNum = page;
      dispatch('getTransfers', getableData.value);
    };

    const onChangeDeals = (page) => {
      getableData.value.pageNum = page;
      dispatch('getDeals', getableData.value);
    };

    const openPublishModal = (data) => {
      const account = accountData.value;
      if (data) {
        publishModal.value = { visible: true, data: { ...account.attributes, ...data, id: route.params.accId } };
      }
    };

    watch(pageSize, () => {
      if (currentPage.value == 1) {
        onChangeDeals(1);
      } else if (currentPage.value == 2) {
        onChangeTransactions(1);
      } else if (currentPage.value == 3) {
        onChangePayments(1);
      } else if (currentPage.value == 4) {
        onChangeTransfers(1);
      }
    });

    onMounted(() => {
      dispatch('getTradingAccount', { id: accId });
      dispatch('getGraphTradingStats', { id: accId, type: 'trading_accounts' });
      dispatch('getDeals', getableData.value);
      route.params.actionType && !visibleModalAction.value
        ? router.push('/trading-account/' + accId + '/page/' + pageNum.value)
        : '';
    });

    const columnsTransactions = computed(() => [
      {
        title: i18n.t('transactions.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: i18n.t('transactions.amount_operation'),
        dataIndex: 'amount_operation',
        key: 'amount_operation',
      },
      {
        title: i18n.t('transactions.balance'),
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: i18n.t('transactions.status'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: i18n.t('transactions.operable_type'),
        dataIndex: 'operable_type_short',
        key: 'operable_type_short',
      },
      {
        title: i18n.t('transactions.comment'),
        dataIndex: 'operable_type',
        key: 'operable_type',
        slots: { customRender: 'operableType' },
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const columnsPayments = computed(() => [
      {
        title: i18n.t('start.request_amount'),
        dataIndex: 'request_amount',
        key: 'request_amount',
        slots: { customRender: 'request_amount', title: 'customTitleSumm' },
      },
      //{
      //  title: i18n.t('start.exchange_amount'),
      //  dataIndex: 'exchange_amount',
      //  key: 'exchange_amount',
      //  slots: { customRender: 'exchange_amount' },
      //},
      //{
      //  title: i18n.t('start.exchange_rate'),
      //  dataIndex: 'exchange_rate',
      //  key: 'exchange_rate',
      //  slots: { customRender: 'exchange_rate' },
      //},
      {
        dataIndex: 'operation_status_text',
        key: 'operation_status_text',
        slots: { customRender: 'operation_status_text', title: 'customTitleStatus' },
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { customRender: 'created_at', title: 'customTitleDate' },
      },
      {
        title: '',
        width: 0,
      },
    ]);
    const columnsTransfers = computed(() => [
      {
        title: i18n.t('transactions.account_from_type'),
        dataIndex: 'account_from_type',
        key: 'account_from_type',
      },
      {
        title: i18n.t('transactions.account_to_type'),
        dataIndex: 'account_to_type',
        key: 'account_to_type',
      },
      {
        dataIndex: 'amount',
        key: 'amount',
        slots: { title: 'customTitleAmount' },
      },
      {
        title: i18n.t('transactions.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { title: 'customTitleCreated' },
      },
      {
        dataIndex: 'due_at',
        key: 'due_at',
      },
      {
        dataIndex: 'status',
        key: 'status',
        slots: { title: 'customTitleStatus' },
      },

      {
        title: '',
        width: 0,
      },
    ]);

    const dataSourceTransactions = computed(() =>
      transactionsData.value
        ? transactionsData.value.map((transaction, key) => {
            const { attributes, id } = transaction;
            const {
              operable_type,
              amount_before,
              amount_after,
              amount_operation,
              operation_currency,
              created_at,
              status,
              operation_info,
              comment,
              user,
            } = attributes;
            return {
              key: key + 1,
              id,
              operable_type,
              balance: (
                <p style="display:flex;">
                  {' '}
                  {i18n.n(+amount_before, 'decimal')} {i18n.t('transactions.' + operation_currency)}{' '}
                  <span
                    style="margin:0 10px; white-space:nowrap;word-wrap:nowrap;"
                    class={+amount_after > +amount_before ? 'arrow-more' : 'arrow-less'}
                  >
                    ->
                  </span>{' '}
                  {i18n.n(+amount_after, 'decimal')} {i18n.t('transactions.' + operation_currency)}
                </p>
              ),
              operable_type_short: i18n.t('transactions.' + operable_type, 2),
              amount_operation:
                i18n.n(+amount_operation, 'decimal') + ' ' + i18n.t('transactions.' + operation_currency),
              created_at: i18n.d(created_at, 'long'),
              status: i18n.t('transactions.' + status),
              normal_date: created_at,
              operation_info,
              user,
              comment,
            };
          })
        : [],
    );

    const dataSourcePayments = computed(() => {
      let depositsArr = [];
      if (accountType == 'real' && coinPaymentsData.value) {
        depositsArr = coinPaymentsData.value.map((payment, key) => {
          const { operation_id, request_amount, operation_status_text, active, created_at, exchange_currency, id } =
            payment.attributes;
          return {
            key: key + 1,
            operation_id,
            request_amount,
            exchange_currency,
            operation_status_text,
            created_at,
            active,
            id,
          };
        });
      } else {
        depositsArr = coinPaymentsData.value.map((payment, key) => {
          const { id, attributes } = payment;
          const { amount, amount_currency, status, created_at } = attributes;
          return {
            key: key + 1,
            operation_id: id,
            request_amount: amount,
            exchange_currency: amount_currency,
            operation_status_text: status,
            created_at,
            id,
          };
        });
      }

      return depositsArr;
    });

    const dataSourceTransfers = computed(() =>
      transfersData.value
        ? transfersData.value.map((transfer, key) => {
            const { id } = transfer;
            const { account_to_type, account_from_type, amount, status, created_at } = transfer.attributes;
            return {
              key: key + 1,
              id,
              account_from_type: i18n.t('accounts.' + account_from_type + 'Name'),
              account_to_type: i18n.t('accounts.' + account_to_type + 'Name'),
              amount: i18n.n(+amount, 'decimal') + ' USDT',
              created_at: i18n.d(created_at, 'short'),
              due_at: null,
              status: i18n.t('transactions.' + status),
              change: null,
            };
          })
        : [],
    );

    return {
      i18n,
      accountData,
      visibleModalAction,
      columnsTransactions,
      transactionsData,
      dataSourceTransactions,
      transfersData,
      transactionView,
      coinPaymentsData,
      coinPaymentInfo,
      columnsPayments,
      dataSourcePayments,
      visibleModalDeposit,
      closeTradingModal,
      depositId,
      transfersMeta,
      paymentsMeta,
      pageNum,
      pageSize,
      isPc,
      faSort,
      faSortUp,
      faSortDown,
      order,
      linkValue,
      transactionsMeta,
      activeKey,
      dataSourceTransfers,
      columnsTransfers,
      publishModal,
      openPublishModal,
      changeTab,
      sortTablePayments,
      onChangePayments,
      onChangeTransfers,
      onChangeTransactions,
      onChangeDeals,
      openModalDeposit,
      openModal,
      closeModal,
      checkModal,
      openModalCheck,
    };
  },
};

export default TradingAccountInfoPage;
</script>
